window.angular.module('MyHippoProducer.Components').component('navBar', {
    restrict: 'E',
    scope: {},
    templateUrl: 'components/navbar/navbar.html',
    controller: function ($rootScope, $window, $log, $scope, APIService, UserService, $element, $state, $location, FlagshipService) {
        $log.info('NavBar Controller');

        const protocol = $location.protocol();
        const reactProducerDomain = window.REACT_PRODUCER_DOMAIN;
        const subdomain = $window.location.host.split('.')[0];
        const producersReactDomain = `${protocol}://${subdomain}.${reactProducerDomain}`;

        $scope.generateContactUsLink = () => {
            $window.open(`${producersReactDomain}/contact-us`);
        };
        $scope.generateResourceCenterLink = () => {
            $window.open(window.appConfig.RESOURCE_CENTER_URL);
        };
        $scope.generateRenewalInspectionDocsLink = () => {
            $window.open(`${producersReactDomain}/renewal-inspections`);
        };

        const recalculatePadding = () => {
            const navPaddingStyle = $element.find('.nav-padding')[0].style;
            navPaddingStyle.marginBottom = $scope.showPolicyHeader ? '0px' : '30px';
            navPaddingStyle.height = `${ $element.find('.navbar')[0].offsetHeight}px`;
        };

        angular.element($window).bind('resize', function() {
            recalculatePadding();
        });

        $scope.$watch(function () { return $state.current.name + $element.find('.navbar')[0].offsetHeight; }, function () {
            $scope.showPolicyHeader = $state.current.name === 'portal.policyDetailsV2';
            recalculatePadding();
        });

        $scope.logout = () => APIService.logout().then(() => {
            $rootScope.$broadcast('user.logout');
        });

        APIService.findPendingPolicies({ limit: 10, countLimit: 10 }).then(function (results) {
            $scope.totalPendingPolicies = results.count;
            if (results.count >= 10) {
                $scope.pendingPoliciesBadge = `9+`;
            } else {
                $scope.pendingPoliciesBadge = results.count;
            }
        });

        $scope.user = UserService.getUser();
        $scope.canEditOrganizations = UserService.canEditOrganizations();
        $scope.canEditProducers = UserService.canEditProducers();
        $scope.canViewReports = UserService.canViewReports();
        $scope.canViewDocuments = UserService.canViewDocuments();
        $scope.canViewAdminPanel = UserService.canViewAdminPanel();
        $scope.canViewOrgFromProfileDropdown = UserService.canViewOrgFromProfileDropdown();
        $scope.partnerStyling = UserService.getPartnerStyling();

        // Resource Center feature modal;
        $scope.dontShowAgainResourceModal = false;
        $scope.shouldDisplayResourceCenterModal = false; // {disable the feature - uncomment to enable} !($window.localStorage.getItem('hasResourceCenterModalSeen') === 'true');
        $scope.closeResourceCenterModal = () => {
            $scope.shouldDisplayResourceCenterModal = false;
            $window.localStorage.setItem('hasResourceCenterModalSeen', $scope.dontShowAgainResourceModal);
        };
        $scope.onDontShowResourceCenterModalAgainClicked = (value) => {
            $scope.dontShowAgainResourceModal = (value === true);
        };

        // Get PPV2 Flagship config
        $scope.ppv2FeatureFlags = {
            enablePoliciesPage: false,
            enableOneTimePayment: false
        }; // PPV2 features are disabled by default
        FlagshipService.getCampaignData({campaignID: window.appConfig.FLAGSHIP_PPV2_CAMPAIGN_ID}).then((res) => {
            if (res.data &&
                res.data.variation &&
                res.data.variation.modifications &&
                res.data.variation.modifications.value
            ) {
                $scope.ppv2FeatureFlags = res.data.variation.modifications.value;
            }
        });
        // Set up PPV2 features
        $scope.redirectToPoliciesPage = () => {
            if ($scope.ppv2FeatureFlags.enablePoliciesPage) {
                $window.location.href = `${producersReactDomain}/policies`;
            }
            else {
                $state.go('portal.policiesList');
            }
        };

        $scope.$watch('ppv2FeatureFlags', (newFeatureFlagsSettings) => {
            if(!newFeatureFlagsSettings) return;
            $scope.canViewBillingMenu = Boolean(newFeatureFlagsSettings.enableOneTimePayment);
        });
        $scope.redirectToOneTimePayment = () => $window.location.href = `${producersReactDomain}/one-time-payment`;
    }
});
